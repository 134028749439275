const ORIGIN = 'https://prmst.dogalzeka.com.tr/v1';

export const baseURL = ORIGIN;
export const apiURL = ORIGIN + '/api/public';
export const authURL = ORIGIN + '/auth';
export const publicPatientURL = ORIGIN + '/api/publicpatient';

export const urlBaseName = '';
export const DR_HOST_ADDRESS = null;
export const INQUIRY_HOST_ADDRESS = 'https://drfindt.dogalzeka.com.tr/inquiry';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyB0qmvJGoSlVkCgHCmlfWtJfnDnp1IMsbE';

export const BUILD_LANGUAGE = 'en';
export const PRODUCT_NAME = BUILD_LANGUAGE === 'tr' ? 'PRMS-T' : 'DRFIND-T.EU';
export const BUILD_LOCALE = BUILD_LANGUAGE === 'tr' ? 'tr-TR' : 'en-US';
